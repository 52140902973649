import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import { map, keys } from 'lodash';
import styles from './styles';
import axios from 'axios';

export interface ClusterListParams {
  country: string;
  state: string;
  area: string;
}

enum SUBPAGES {
  'donation' = '我要捐赠',
  'donationSearch' = '捐赠查询',
  // 'projectIntroduction' = '项目介绍',
  'donationExplanation' = '捐赠说明',
  'subscribe' = '铁山寺服务号'
}

const heartIcon = 'https://sinacloud.net/xbucket1/img/heartIcon.png';

class EnterContainer extends Component<any, {}> {
  constructor(props: any) {
    super(props);
    document.title = '铁山寺捐赠服务';
  }

  componentWillMount() {
    // validate session by cookies, tss_0_session_id
    let agent = navigator.userAgent.toLowerCase();
    if (agent.match(/MicroMessenger/i) === null) {
      return;
    }

    axios
      .get('/ss/session/validate')
      .then(() => {
        // 200 returned, session is valid
        return;
      })
      .catch(reason => {
        // 403 returned, refresh session
        window.location.href = '/ss/login';
      });
  }

  handleClick = (e: React.MouseEvent) => {
    const name = e.target && e.currentTarget.getAttribute('name');
    let newPathLocation = '';
    if (name === SUBPAGES.donation) {
      window.alert('感谢您的访问。系统维护中，上线时间待定，敬请期待！');
      return;
      newPathLocation = '/views/adona';
    } else if (name === SUBPAGES.donationSearch) {
      newPathLocation = '/views/sdona';
    } else if (name === SUBPAGES.subscribe) {
      window.location.href =
        'https://mp.weixin.qq.com/s/9akH0Lq_qHvN-B9tGg9MFg#' + Math.random();
      return;
    } else if (name === SUBPAGES.donationExplanation) {
      window.location.href =
        'https://mp.weixin.qq.com/s/rXWvsLgeKprdn3mLFu0x8w#' + Math.random();
      return;
    }
    this.props.history.push(newPathLocation);
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <Grid
          container
          spacing={24}
          justify="center"
          className={classes.content}
        >
          <Grid item xs={6} sm={6}>
            <List>
              {map(keys(SUBPAGES), (subPageName: any) => (
                <ListItem
                  button
                  key={subPageName}
                  name={SUBPAGES[subPageName]}
                  className={classes.item}
                  classes={{ button: classes.button }}
                  onClick={this.handleClick}
                >
                  <ListItemIcon>
                    <img
                      alt="heartIcon"
                      src={heartIcon}
                      className={classes.heartIcon}
                    />
                  </ListItemIcon>
                  <Grid
                    container
                    spacing={24}
                    alignItems="center"
                    justify="space-between"
                    className={classes.content}
                  >
                    <Typography variant="inherit">
                      {SUBPAGES[subPageName]}
                    </Typography>
                  </Grid>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default compose(
  withStyles(styles, {
    withTheme: true
  }),
  withRouter
)(EnterContainer);
