const color = {
  50: '#DFF0D0',
  100: '#C7E59C',
  200: '#AADB1E',
  300: '#85C81A',
  400: '#60B515',
  500: '#62A420',
  600: '#48960C',
  700: '#318700',
  800: '#266900',
  900: '#1D5100',
  A100: '#C7E59C',
  A200: '#AADB1E',
  A400: '#60B515',
  A700: '#318700',
  contrastDefaultColor: 'light'
};

export default color;
