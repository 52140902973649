import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: process.env.NODE_ENV !== 'production',
    load: 'all',
    interpolation: {
      escapeValue: false // not needed for react!!
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      queryStringParams: {
        v: process.env.REACT_APP_VERSION || new Date().getTime()
      } // timeStamp for development
    },
    react: {
      wait: true
    }
  });

export default i18n;
