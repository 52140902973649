const color = {
  50: '#FAFAFA',
  100: '#F2F2F2',
  200: '#EEEEEE',
  300: '#DDDDDD',
  400: '#CCCCCC',
  500: '#9A9A9A',
  600: '#737373',
  700: '#565656',
  800: '#444444',
  900: '#313131',
  A100: '#F2F2F2',
  A200: '#EEEEEE',
  A400: '#CCCCCC',
  A700: '#565656',
  contrastDefaultColor: 'light'
};

export default color;
