const color = {
  50: '#FFF0EE',
  100: '#F5DBD9',
  200: '#F8B7B6',
  300: '#F89997',
  400: '#F76F6C',
  500: '#F54F47',
  600: '#F52F22',
  700: '#E62700',
  800: '#C92100',
  900: '#A32100',
  A100: '#F5DBD9',
  A200: '#F8B7B6',
  A400: '#F76F6C',
  A700: '#A32100',
  contrastDefaultColor: 'light'
};

export default color;
