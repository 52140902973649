import { createStyles } from '@material-ui/core/styles';

const styles = (theme: any) =>
  createStyles({
    card: {
      // maxWidth: 400
    },

    alwaysShownText: {
      paddingTop: 0,
      paddingBottom: 0
    },

    expandButton: {
      border: '1px solid #976c51',
      color: '#b69b8c',
      paddingTop: 0,
      paddingBottom: 0,
      borderRadius: '8px 8px',

      '& span p': {
        color: '#976c51'
      }
    },

    actions: {
      display: 'flex',
      justifyContent: 'center',

      '& button': {
        marginLeft: 0
      }
    },

    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest
      })
    },

    expandOpen: {
      transform: 'rotate(180deg)'
    },

    title: {
      color: '#976c51',
      fontSize: '1.2rem',
      padding: 0,

      '& span': {
        paddingLeft: '5px',
        borderLeft: '3px solid #976c51'
      }
    },

    pic: {
      width: '100%',
      height: '120px'
    },

    construction: {
      height: '225px',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/board.png"),url("https://sinacloud.net/xbucket1/img/construction.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '8px'
    },

    sutrasPrint: {
      height: '225px',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/board.png"),url("https://sinacloud.net/xbucket1/img/scripture.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '8px'
    },

    triratna: {
      height: '225px',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/board.png"),url("https://sinacloud.net/xbucket1/img/scripture.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '8px'
    },

    tssCharity: {
      height: '25%',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/tssCharity.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '8px'
    },

    flower: {
      height: '225px',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/board.png"),url("https://sinacloud.net/xbucket1/img/flower.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '8px'
    },

    fruit: {
      height: '225px',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/board.png"),url("https://sinacloud.net/xbucket1/img/fruit.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '8px'
    },

    lamp: {
      height: '225px',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/board.png"),url("https://sinacloud.net/xbucket1/img/lamp.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '8px'
    },

    buddist_meal: {
      height: '225px',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/board.png"),url("https://sinacloud.net/xbucket1/img/buddist_meal.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '8px'
    },

    buddist_party_meal: {
      height: '225px',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/board.png"),url("https://sinacloud.net/xbucket1/img/buddist_party_meal.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '8px'
    },

    random: {
      height: '225px',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/board.png"),url("https://sinacloud.net/xbucket1/img/random.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '8px'
    },

    bigSize: {
      fontSize: '1rem'
    },

    slogonContainer: {
      top: '135px',
      paddingLeft: '5px',
      position: 'relative'
    },
    slogon: {
      color: 'white',
      fontSize: '1.2rem',
      textUnderlinePosition: 'below',
      textDecoration: 'underline'
    },

    desc: {
      color: 'white',
      fontSize: '0.75rem'
    }
  });
export default styles;
