import React, { Component } from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { map } from 'lodash';

import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';

import styles from './styles';

export interface DonationMoneyInputProps extends WithStyles<typeof styles> {
  handleMoneyInputChange(e: any, option?: number): void;
}

const moneyOptions = [1, 5, 10, 50, 100, 200, 500, 1000];

class DonationMoneyInput extends Component<DonationMoneyInputProps, {}> {
  state = {
    value: moneyOptions[0],
    error: ''
  };

  handleChange(e: any, option: number) {
    this.setState({ value: option });
    this.props.handleMoneyInputChange(e, option);
  }

  handleInputChange = (e: any) => {
    const value = e.target && e.target.value;
    // this.setState({value});
    const reg = /^\s*[0-9.]+\s*$/;
    if (reg.test(value)) {
      this.setState({
        value,
        error: ''
      });
    } else {
      this.setState({
        value,
        error: '请输入数字'
      });
    }

    this.props.handleMoneyInputChange(e);
  };

  render() {
    const { classes } = this.props;
    const { value, error } = this.state;

    return (
      <FormControl className={classes.formControl}>
        <FormLabel>捐赠金额:</FormLabel>
        <div className={classes.inputContainer}>
          <Grid
            container
            spacing={8}
            justify="flex-start"
            className={classes.group}
          >
            {map(moneyOptions, option => (
              <Grid item xs={3} sm={3} key={option}>
                <Button
                  fullWidth
                  variant="outlined"
                  size="large"
                  color="primary"
                  className={`${classes.moneyButton} ${
                    option === value ? classes.selected : null
                  }`}
                  name="money"
                  onClick={e => this.handleChange(e, option)}
                >
                  {option}
                </Button>
              </Grid>
            ))}
          </Grid>
          <InputBase
            id="money-input"
            name="money"
            type="number"
            value={value}
            classes={{
              root: classes.bootstrapRoot,
              input: classes.bootstrapInput
            }}
            startAdornment={
              <InputAdornment position="start">
                <FormLabel className={classes.bigSize}>其它金额:</FormLabel>
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <FormLabel className={classes.bigSize}>元</FormLabel>
              </InputAdornment>
            }
            aria-describedby="money-input-error-text"
            onChange={this.handleInputChange}
          />
          {error && (
            <FormHelperText
              id="money-input-error-text"
              className={classes.errorText}
            >
              {error}
            </FormHelperText>
          )}
        </div>
      </FormControl>
    );
  }
}

export default withStyles(styles, { withTheme: true })(DonationMoneyInput);
