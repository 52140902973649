import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutLineIcon from '@material-ui/icons/CheckCircleOutline';

const GreenCheckbox: any = withStyles({
  root: {
    color: '#866149',
    '&$checked': {
      color: '#866149'
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

function AgreementCheckbox(props: any) {
  const [state, setState] = React.useState({
    agreementStatus: props.agreementStatus
  });

  const handleChange = (name: string) => (event: any) => {
    setState({ ...state, [name]: event.target.checked });

    props.handleCheckboxChange({
      name: 'agreementStatus',
      value: event.target.checked
    });
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <GreenCheckbox
            color="default"
            checked={state.agreementStatus}
            onChange={handleChange('agreementStatus')}
            value="agreementStatus"
            icon={<CheckCircleOutLineIcon style={{ color: 'grey' }} />}
            checkedIcon={<CheckCircleIcon style={{ color: '#866149' }} />}
          />
        }
        label={
          <span style={{ color: '#866149', fontSize: '1rem' }}>
            同意&nbsp;
            <a
              href={
                'https://mp.weixin.qq.com/s/rXWvsLgeKprdn3mLFu0x8w#' +
                Math.random()
              }
            >
              铁山寺捐赠协议
            </a>
          </span>
        }
      />
    </FormGroup>
  );
}

export default AgreementCheckbox;
