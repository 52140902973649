export function checkWxBridge() {
  // console.log('before WeixinJSBridge: ' + WeixinJSBridge);

  return new Promise((resolve, reject) => {
    if (typeof (window as any).WeixinJSBridge === 'undefined') {
      // @ts-nocheck
      if (document.addEventListener) {
        document.addEventListener('WeixinJSBridgeReady', resolve, false);
      } else if ((document as any).attachEvent) {
        (document as any).attachEvent('WeixinJSBridgeReady', resolve);
        (document as any).attachEvent('onWeixinJSBridgeReady', resolve);
      }
    } else {
      resolve();
      // console.log('before WeixinJSBridge resolved: ' + WeixinJSBridge);
    }
  });
}
