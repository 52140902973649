import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DONATION_TYPES } from '../TempleConstruction';
import { DONATION_TYPES_R } from '../TempleConstruction';
import { map, keys } from 'lodash';

import { SingleCard } from '../TempleConstruction';
import styles from './styles';
import axios from 'axios';

export interface ClusterListParams {
  country: string;
  state: string;
  area: string;
}

enum DONATION_HEADER_NAME {
  donor = '捐赠人',
  amount = '金额',
  item = '项目',
  date = '日期',
  sn = '编号'
}

interface DonationInfo {
  donor: string;
  amount: string;
  // item: string;
  date: string | number;
  sn: string;
}

const back2home = 'http://sinacloud.net/xbucket1/img/back2home.png';

class HonorCertification extends Component<any, any, {}> {
  static defaultProps = {
    type: 'constructor'
  };

  constructor(props: any) {
    super(props);
    document.title = '捐赠证书';

    this.state = {
      donationInfo: {
        donor: '',
        amount: '',
        item: '',
        date: '',
        sn: ''
      },
      type: keys(DONATION_TYPES)[0],
      loading: false,
      error: ''
    };
  }

  componentDidMount() {
    axios
      .get('/ss/certificates/' + this.props.match.params.sn)
      .then(response => {
        // alert('1');
        // alert(JSON.stringify(response.data));
        let type = DONATION_TYPES_R[response.data.item];

        this.setState({
          donationInfo: response.data,
          loading: false,
          type: type
        });
      })
      .catch(reason => {
        alert('获取捐赠证书出收错: ' + reason);
      });
  }

  handleOnclick = () => {};

  render() {
    const { classes } = this.props;
    const { donationInfo, loading, type } = this.state; // , error

    // alert('2');
    // alert(JSON.stringify(donationInfo));
    return loading ? (
      <div className={classes.loadingContainer}>
        <CircularProgress className={classes.progress} />
      </div>
    ) : (
      <div className={classes.container}>
        <SingleCard
          backgroundType={type}
          donated
          handleOnClick={this.handleOnclick}
          classes={classes}
        />

        <div className={classes.certificate}>
          <Table className={classes.table}>
            <TableBody>
              {map(donationInfo, (value, key: any) => (
                <TableRow className={classes.row} key={key}>
                  <TableCell
                    component="th"
                    scope="row"
                    padding="none"
                    align={'left'}
                  >
                    {DONATION_HEADER_NAME[key]}
                  </TableCell>
                  <TableCell align="left">{value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <div className={classes.shareFriendCircle}>
          <a href={'/views/home'}>
            <img alt="返回首页" src={back2home} />
          </a>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(HonorCertification)
);
