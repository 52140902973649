import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { DONATION_TYPES } from '../../TempleConstruction';
import { DONATION_TYPES_NO } from '../../TempleConstruction';
import { filter, isEmpty, concat, intersection, size } from 'lodash';

import DonationTypeSelection from '../DonationTypeSelection';
import DonationTable from '../DonationTable';
import styles from './styles';
import axios from 'axios';

const donation = {
  donator: '马小喜',
  type: '寺院建设',
  amount: '100 元',
  date: '2019/06/01',
  sn: '20190601106'
};

const donation2 = {
  donator: '马小吉',
  type: '供养三宝',
  amount: '50 元',
  date: '2019/06/01',
  sn: '20190601111'
};

const donation3 = {
  donator: '马小庆',
  type: '经书助印',
  amount: '90 元',
  date: '2019/06/01',
  sn: '20190601107'
};

const donation4 = {
  donator: '马小祥',
  type: '铁山慈善',
  amount: '80 元',
  date: '2019/06/01',
  sn: '20190601108'
};

function getDonationList() {
  let donationList = [donation, donation, donation];
  donationList = concat(donationList, [donation2, donation2, donation2]);
  donationList = concat(donationList, [donation3, donation3, donation3]);
  donationList = concat(donationList, [donation4, donation4, donation4]);
  donationList = concat(donationList, [donation, donation, donation]);
  donationList = concat(donationList, [donation2, donation2, donation2]);
  donationList = concat(donationList, [donation3, donation3, donation3]);
  donationList = concat(donationList, [donation4, donation4, donation4]);

  return donationList;
}

class DonationSearch extends Component<any, any, {}> {
  constructor(props: any) {
    super(props);
    document.title = '捐赠查询';
    this.state = {
      searchInput: '',
      selectedType: 'all',
      donations: []
    };
  }

  static defaultProps = {
    type: 'constructor'
  };

  componentWillMount() {
    let agent = navigator.userAgent.toLowerCase();
    if (agent.match(/MicroMessenger/i) === null) {
      return;
    }

    // validate session by cookies, tss_0_session_id
    axios
      .get('/ss/session/validate')
      .then(() => {
        // 200 returned, session is valid
        return;
      })
      .catch(reason => {
        // 403 returned, refresh session
        window.location.href = '/ss/login';
      });
  }

  componentDidMount() {
    this.handleTypeChange('all');
  }

  getFilteredBySearchInput = (donations: any[], searchInput: string) => {
    let filteredDonations = donations;
    // Filter by input
    if (!isEmpty(searchInput)) {
      filteredDonations = filter(filteredDonations, (item: any) => {
        const donatorStr = item.donator;
        const donatorCharacterArray = donatorStr.split('');
        const searchInputCharacterArray = searchInput.split('');

        const commonCharacters = intersection(
          donatorCharacterArray,
          searchInputCharacterArray
        );
        return size(commonCharacters) > 0;
      });
    }

    return filteredDonations;
  };

  handleTypeChange = (selectionType: string) => {
    // const { searchInput } = this.state;

    let qeury_url = '/ss/recent';
    if (selectionType === 'mine') {
      let agent = navigator.userAgent.toLowerCase();
      if (agent.match(/MicroMessenger/i) === null) {
        return;
      }

      qeury_url = '/ss/my-recent';
    } else if (selectionType !== 'all') {
      qeury_url += '?item_id=' + DONATION_TYPES_NO[selectionType as any];
    }

    axios
      .get(qeury_url)
      .then(response => {
        // Filter by input
        // alert(response.data);

        this.setState({
          selectedType: selectionType,
          donations: response.data
        });
        return;
      })
      .catch(reason => {
        // 403 returned, refresh session
        alert('failed to fetch donation list:' + reason);
      });
  };

  handleSearchInputChange = (value: string) => {
    const { selectedType } = this.state;
    const donations = getDonationList();
    let filteredDonations = isEmpty(value)
      ? donations
      : this.getFilteredBySearchInput(donations, value);
    // filter(donations, (item: any) => (indexOf(item.donator, value) > -1));

    // Filtered by type
    if (selectedType !== 'all') {
      filteredDonations = filter(filteredDonations, [
        'type',
        DONATION_TYPES[selectedType as any]
      ]);
    }

    this.setState({
      searchInput: value,
      donations: filteredDonations
    });
  };

  render() {
    const { classes } = this.props;
    const { donations } = this.state;

    return (
      <div className={classes.container}>
        <div className={classes.fixedContainer}>
          <DonationTypeSelection
            onTypeSelectionChange={this.handleTypeChange}
          />
        </div>
        <DonationTable donations={donations} />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(DonationSearch)
);
