import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withCookies, ReactCookieProps } from 'react-cookie';
import './App.module.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import { WithWidth } from '@material-ui/core/withWidth';
import withWidth from '@material-ui/core/withWidth';
import withRoot from '../withRoot';
import { compose } from 'recompose';

import MainContainer from '../../../components/MainContainer';

export interface AppProps
  extends WithWidth,
    RouteComponentProps<any>,
    ReactCookieProps {}

function App() {
  document.title = '铁山寺捐赠服务';
  return (
    <React.Fragment>
      <CssBaseline />
      <div className="App">
        <MainContainer />
      </div>
    </React.Fragment>
  );
}

export default compose(withRoot, withWidth(), withCookies, withRouter)(App);
