import React, { Component, Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import InputBase from '@material-ui/core/InputBase';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Fab from '@material-ui/core/Fab';
import axios from 'axios';

import { map, keys, toNumber } from 'lodash';

import { checkWxBridge } from '../../utils';
import { DONATION_TYPES, DONATION_TYPES_NO } from '../../TempleConstruction';
import DonationMoneyInput from '../DonationMoneyInput';
import AgreementCheckbox from '../AgreementCheckbox';
// import Cookies from 'universal-cookie';

import styles from './styles';
import { isNullOrUndefined } from 'util';

const templeName = 'https://sinacloud.net/xbucket1/img/templeName.png';

export interface DonationInputsCardProps
  extends WithStyles<typeof styles>,
    RouteComponentProps<any> {
  type: any;
}

const formInputs = {
  // typeInput: '捐赠项目',
  donor: '捐赠人:'
  // telephone: '联系电话'
};

class DonationInputsCard extends Component<DonationInputsCardProps, any, {}> {
  static defaultProps = {
    type: keys(DONATION_TYPES)[0]
  };

  constructor(props: DonationInputsCardProps) {
    super(props);
    const type =
      sessionStorage.getItem('donationType') ||
      this.props.type ||
      keys(DONATION_TYPES)[0];
    // this.props.type ||
    // (this.props.match.params && this.props.match.params.type) ||
    // sessionStorage.getItem('donationType');
    document.title = DONATION_TYPES[type];

    this.state = {
      typeInput: DONATION_TYPES[type],
      donor: '',
      // telephone: '',
      money: 1,
      prayMessage: '',
      agreementStatus: true,
      isPaying: false
      // donationInfo: {},
      // donationLoading: true,
      // donationError: ''
    };
  }

  // state = {
  //   typeInput: DONATION_TYPES[this.props.type],
  //   donor: '',
  //   // telephone: '',
  //   money: 1,
  //   prayMessage: '',
  //   agreementStatus: false
  // };

  sessionValidate = () => {
    let agent = navigator.userAgent.toLowerCase();
    if (agent.match(/MicroMessenger/i) === null) {
      return;
    }

    axios
      .get('/ss/session/validate')
      .then(() => {
        // 200 returned, session is valid
        return;
      })
      .catch(reason => {
        // 403 returned, refresh session
        window.location.href = '/ss/login';
      });
  };

  componentWillMount() {
    // // validate session by cookies, tss_0_session_id
    this.sessionValidate();
    // axios
    //   .get('/ss/session/validate')
    //   .then(() => {
    //     // 200 returned, session is valid
    //     return;
    //   })
    //   .catch(reason => {
    //     // 403 returned, refresh session
    //     window.location.href = '/ss/login';
    //   });
  }

  handleChange = (event: any, option?: number) => {
    const name =
      (event.target && event.target.name) ||
      (event.currentTarget && event.currentTarget.name);
    let value = event.target && event.target.value;
    if (name === 'money') {
      value = option || toNumber(value);
    }

    this.setState({ [name]: value });
  };

  handleAgreenmentCheckboxChange = (checkboxObj: {
    name: string;
    value: boolean;
  }) => {
    this.setState({
      agreementStatus: checkboxObj.value
    });
  };

  onBridgeReady = (response: any) => {
    const { appId, timeStamp, sign, nonceStr, signType } = response;

    // console.log('appId: ' + appId);
    // console.log('timeStamp: ' + timeStamp);
    // console.log('sign: ' + sign);

    //   if (typeof WeixinJSBridge === "undefined") {
    //     if (document.addEventListener) {
    //         document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
    //     } else if (document.attachEvent) {
    //         document.attachEvent('WeixinJSBridgeReady', onBridgeReady)
    //         document.attachEvent('onWeixinJSBridgeReady', onBridgeReady)
    //     }
    // } else {
    checkWxBridge().then(() => {
      (window as any).WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        {
          appId, // 公众号名称，由商户传入
          timeStamp, // 时间戳，自1970年以来的秒数
          nonceStr, // 随机串
          package: response.package, // 预支付交易码
          signType: signType, // 微信签名方式：
          paySign: sign // 微信签名
        },
        // todo: how to store response.sn ???

        (res: any) => {
          if (res.err_msg === 'get_brand_wcpay_request:ok') {
            window.location.href = `/views/acert/${response.sn}`;
          } else if (res.err_msg === 'get_brand_wcpay_request:cancel') {
            alert('用户取消支付');
          } else {
            alert('支付失败,请重试');
          }
          this.setState({ isPaying: false });
        }
      );
    });
  };

  postFormData = () => {
    const { type } = this.props;
    const { donor, money, prayMessage } = this.state;
    const itemId = DONATION_TYPES_NO[type];
    const postData = {
      donor,
      total_fee: money,
      item_id: itemId,
      item_name: DONATION_TYPES[this.props.type],
      wish: prayMessage
    };
    if (isNullOrUndefined(postData.donor) || postData.donor === '') {
      postData.donor = '爱心人士';
    }

    // Note:  should write as ` if x < 0.01`
    if (!(Number(postData.total_fee) >= 0.01)) {
      alert('请输入有效的金额.');
      this.setState({ isPaying: false });
      return;
    }

    if (Number(postData.total_fee) >= 1000) {
      if (
        !window.confirm(
          '您输入的金额为: ' + postData.total_fee + '元, 数额较大，请再次确认'
        )
      ) {
        this.setState({ isPaying: false });
        return;
      }
    }

    axios
      .post(`/ss/items/${itemId}`, postData, {})
      .then(response => {
        // Invoke wechat's request
        this.onBridgeReady(response.data);
      })
      .catch(error => {
        alert(error);
        this.setState({ isPaying: false });
        console.log(error);
      });
  };

  submitForms = () => {
    // send data to backend
    console.log(JSON.stringify(this.state));
    let agent = navigator.userAgent.toLowerCase();
    if (agent.match(/MicroMessenger/i) === null) {
      alert('只能在微信内捐款');
      return;
    }

    this.setState({ isPaying: true });
    this.postFormData();
  };

  render() {
    const { classes } = this.props;
    const { prayMessage, agreementStatus } = this.state;

    // const typeFromProps = type || keys(DONATION_TYPES)[0];

    return (
      <Fragment>
        <div className={classes.inputsContainer}>
          {map(formInputs, (value, key: string) => (
            <FormControl className={classes.margin} key={key}>
              <FormLabel>捐赠人:</FormLabel>
              <InputBase
                id={`${key}-input`}
                classes={{
                  root: classes.bootstrapRoot,
                  input: classes.bootstrapInput
                }}
                name={key}
                value={(this.state as any)[key]}
                disabled={key === 'typeInput'}
                onChange={e => this.handleChange(e)}
              />
            </FormControl>
          ))}

          <DonationMoneyInput handleMoneyInputChange={this.handleChange} />

          <FormControl
            className={`${classes.margin} ${classes.prayMessageContainer}`}
          >
            <FormLabel
              htmlFor="prayMessage"
              className={classes.prayMessageLabel}
            >
              祈愿/回向:
            </FormLabel>
            <TextField
              id="prayMessage"
              multiline
              rows="2"
              className={classes.textField}
              margin="none"
              variant="outlined"
              name="prayMessage"
              value={prayMessage}
              onChange={this.handleChange}
            />
          </FormControl>

          <form className={`${classes.margin} ${classes.checkedContainer}`}>
            <TextField
              id="agreement"
              className={classNames(
                classes.margin,
                classes.textField,
                classes.agreement
              )}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    className={classes.adornment}
                  >
                    <AgreementCheckbox
                      agreementStatus={agreementStatus}
                      handleCheckboxChange={this.handleAgreenmentCheckboxChange}
                    />
                  </InputAdornment>
                )
              }}
            />
          </form>

          <div className={classes.submitButtonContainer}>
            <Fab
              variant="extended"
              size="medium"
              aria-label="Delete"
              classes={{
                extended: classes.submitButton,
                disabled: classes.submitButtonDisabled
              }}
              onClick={this.submitForms}
              disabled={!agreementStatus || this.state.isPaying}
            >
              我要捐赠
            </Fab>
          </div>
        </div>
        <div className={classes.templeName}>
          <img alt="" src={templeName} />
        </div>
      </Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(DonationInputsCard)
);
