import { createStyles } from '@material-ui/core/styles';

const styles = (theme: any) =>
  createStyles({
    container: {
      text: '1rem',
      background: `url("https://sinacloud.net/xbucket1/img/mainShrineHall.jpg") no-repeat center`,
      height: '100vh',
      width: '100vw',
      paddingTop: '20%',
      overflow: 'hidden',
      // backgroundSize: 'cover', // ;或者background-size:100%;
      backgroundSize: '100% 100%',
      maxWidth: '720px'
    },

    content: {
      height: '100%',
      fontSize: '1rem'
    },

    heartIcon: {
      width: '20px',
      height: '16px'
    },

    rightArrowIcon: {
      // width: '14px',
      // height: '14px'
    },

    item: {
      backgroundColor: '#866149',
      marginBottom: '8px',
      color: 'white',
      borderRadius: '20px 20px 20px 20px'
    },

    button: {
      backgroundColor: '#866149',

      '&:focus': {
        backgroundColor: '#866149'
      },

      '&:hover': {
        backgroundColor: '#866149'
      }
    },

    arrowIconContainer: {
      paddingRight: '5px'
    },

    arrowIcon: {
      height: '16px',
      width: '16px'
    }
  });
export default styles;
