import { createStyles } from '@material-ui/core/styles';

const styles = (theme: any) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center'
    },

    formControl: {
      minWidth: 120
    },
    selectEmpty: {
      borderBottom: 'none',
      color: 'white',
      fontSize: '1rem',

      '&:before': {
        borderBottom: 'none'
      },

      '&:after': {
        borderBottom: 'none'
      },

      '& svg': {
        color: 'white'
      }
    },
    item: {
      padding: '5px'
    }
  });
export default styles;
