import React, { PureComponent, Fragment } from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ReactMarkdown from 'react-markdown';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { keys } from 'lodash';

import { DONATION_TYPES, shortDesc } from '../../TempleConstruction';

import styles from './styles';

interface DonationDescriptionExpandCardProps extends WithStyles<typeof styles> {
  type: string;
}

interface DonationFormState {
  expanded: boolean;
}

const descriptions: any = {
  construction: `建造佛庙是净除恶业、障碍及广积福德资粮的有力法门不但能够令自己证悟菩提道，
而且能够圆满饶益众多受苦的有情，使他们解脱，进而证得无上安乐的佛果。

建寺功德之所以不可思议，源于一座寺可以存世百千年，每一个进入寺院的人升起一念对佛法僧三宝的恭敬心,
都可以帮助种植善根、福报。

《莲花经》云，任何善信发心赞助建寺与佛坛，可以获得以下十大功德:

1. 无始以来诸恶业,得以减轻或消除
2. 天人护佑,逢凶化吉;
3. 去除障碍,免夙仇报复之苦;
4. 妖魔邪怪,不能侵犯;
5. 脱离烦恼和无明;
6. 丰衣足食,福禄绵长;
7. 所言所行,人天欢喜;
8. 增加福慧二资粮;
9. 往生善道,相貌端庄，天资福禄兼有;
10. 往生能闻佛法之国土，速证佛果。`,

  triratna: `三宝好福田,一切田中胜，舍一得万报,世世悉安乐！
  三宝，即“佛”、“法”、“僧”三宝，供养三宝是修福修慧。
  
  《布施经》云:「若以上妙饮食供养三宝,得五种利益:身相端严、气力增盛、寿命延长、快乐安隐、成就辩才。
  如是南赡部洲,一切众生、父母妻子、男女眷属,如上布施,随愿所求,无不圆满。
  
  《杂宝藏经》云:「若有人能信心清净,以一掬水,施于佛、僧及以父母、困厄病人，以此功德，数千万劫，受福无穷。`,

  sutrasPrint: `佛说：“末法五百年，我现文字相。”佛教书籍，作为承载思想的媒介，传承佛陀的慈悲与智慧。
印光大师开示助印经书十大利益：

1. 从前所作种种罪过，轻者立即消灭，重者亦得转轻。
2. 常得善神拥护，一切瘟疫水火寇盗刀兵牢狱之灾，悉皆不受。
3. 夙生怨对，咸蒙法益，而得解脱，永免寻仇报复之苦。
4. 夜叉恶鬼，不能侵犯；毒蛇饿虎，不能为害。
5. 心得安慰，日无险事，夜无恶梦；颜色光泽，气力充盛，所作吉利。
6. 至心奉法，虽无希求，自然衣食丰足，家庭和睦，福寿绵长。
7. 所言所行，人天欢喜；任到何方，常为多众倾诚爱戴，恭敬礼拜。
8. 愚者转智，病者转健，困者转亨；为妇女者，报谢之日，捷转男身。
9. 永离恶道，受生善道；相貌端正，天资超越，福禄殊胜。
10. 能为一切众生，种植善根；以众生心，作大福田，获无量胜果；所生之处，常得见佛闻法，直至三慧宏开，六通亲证，速得成佛。
`,
  flower: `佛前供花是提醒弟子要多播种成佛的因（一切利他的善行，一切自净的提升），将来才有成佛的果。
  
代表因的“花”提醒我们要常常自勉自励，检讨自己的行为，向着成佛的目标前进。

《佛为首迦长者说业报差别经》中说供花的十种功德：

    一者、处世如花
    二者、身无臭秽
    三者、福香戒香 遍诸方所
    四者、随所生处 鼻根不坏
    五者、超胜世间 为众归仰
    六者、身常香洁 
    七者、爱乐正法 受持读诵
    八者、具大福报 
    九者、命终生天
    十者、速证涅盘
`,
  fruit: `佛教最重视因果法则，万事万法不离因果，“种善因，得善果；种恶因，得苦果”。细微至一言一行一念，都有因有果。
看到鲜果，便提醒自己要小心观照自己的举心动念，希望有善果，就必定在举心动念间含藏善因善意。

鲜果供在佛案，并不是送给佛菩萨吃，以换取世间报酬。供果属于供食的一种，向佛陀供食是为了祈愿众生身体上飢饿之苦消除，同时心灵上得到「禅悦法食」。
`,
  lamp: `“灯”代表光明与智慧。《六祖坛经》言：“一灯能破千年暗，一智能灭万年愚。”
  
供灯，象征着把佛陀的光明与智慧传递给每一个人，菩提心灯薪火相传。佛说供灯的十种功德：

    一者、照世如灯 
    二者、随所生处 肉眼不坏
    三者、得于天眼
    四者、于善恶法 得善智慧
    五者、除灭大闇 
    六者、得智慧明 
    七者、流转世间 常不在于黑闇之处
    八者、具大福报
    九者、命终生天
    十者、速证涅槃
`,
  buddist_meal: `《盂兰盆经》：若能以饭食、卧具、医药供养十方僧众,藉此十方僧众清净共修，功德回向之力，能使供养者，往昔七世父母、六亲眷属得远离三恶道，现世父母及其本人，长寿无病无恼，衣食自然具足，身心安乐。`,
  buddist_party_meal: `即饭食沙门，又名供僧法会，斋主准备钱财食物，在寺院或者大法会，供养僧侣或者四众，上供十方诸佛，中奉诸圣贤，下及三途六道一切有情，以此培植福报。
僧众受此供养，以诵经礼佛回向护法龙天，使斋主增福增慧、广结佛缘，所愿皆成。

供斋又有香斋、罗汉斋、如意斋、上堂大斋、千僧斋等不同，“凡是有斋主檀越来山，供众千僧大斋，必定有一位罗汉降临应供的”所以供千僧斋，一定会有罗汉化身来应斋，培福很快，功德无量。
`,
  random: `如果您看着都欢喜，但是不确定选哪一项，就随缘捐赠吧。`
};

class DonationDescriptionExpandCard extends PureComponent<
  DonationDescriptionExpandCardProps,
  DonationFormState,
  any
> {
  static defaultProps = {
    type: keys(DONATION_TYPES)[0]
  };

  state = { expanded: false };

  handleExpandClick = () => {
    this.setState((state: DonationFormState) => ({
      expanded: !state.expanded
    }));
  };

  render() {
    const { classes, type } = this.props;
    const { expanded } = this.state;

    console.log('type: ' + type);
    const cardClass = classes && (classes as any)[type];

    const typeFromProps: any = type || keys(DONATION_TYPES)[0];

    return (
      <Card className={classes.card}>
        <CardHeader
          classes={{ title: classes.title }}
          title={
            <Fragment>
              <div className={cardClass}>
                <div className={classes.slogonContainer}>
                  <span className={classes.slogon}>
                    {(shortDesc() as any)[type][0]}
                  </span>
                  <br />
                  <span className={classes.desc}>
                    {(shortDesc() as any)[type][1]}
                  </span>
                  <br />
                  <span className={classes.desc}>
                    {(shortDesc() as any)[type][2]}
                  </span>
                </div>
              </div>
              {/* <img src={typeFromProps} className={classes.pic} /> */}
            </Fragment>
          }
        />
        {!expanded && (
          <CardContent className={classes.alwaysShownText}>
            <ReactMarkdown
              source={descriptions[typeFromProps].slice(0, 15)}
            ></ReactMarkdown>
          </CardContent>
        )}
        <CardActions className={classes.actions} disableActionSpacing>
          <IconButton
            className={classes.expandButton}
            onClick={this.handleExpandClick}
            aria-expanded={expanded}
            aria-label="Show more"
          >
            <Typography className={classes.bigSize}>
              {expanded ? '收回' : '展开'}
            </Typography>
            <ExpandMoreIcon
              className={classnames(classes.expand, {
                [classes.expandOpen]: expanded
              })}
            />
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <ReactMarkdown source={descriptions[typeFromProps]}></ReactMarkdown>
          </CardContent>
        </Collapse>
      </Card>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  DonationDescriptionExpandCard
);
