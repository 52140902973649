import React, { PureComponent } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

import EnterContainer from '../EnterContainer';
import TempleConstruction from '../TempleConstruction';
import DonationForm from '../donationInputs/DonationForm';
import HonorCertification from '../HonorCertification';
import DonationSearch from '../donationListWithSearch/DonationSearch';
import DonationInquiry from '../DonationInquiry';
import DonationSubscription from '../DonationSubscription';
import styles from './styles';

export interface ClusterListParams {
  country: string;
  state: string;
  area: string;
}

class MainContainer extends PureComponent<any, {}> {
  render() {
    return (
      <Switch>
        <Route path={'/views/home'} render={() => <EnterContainer />} />

        <Route path={'/views/adona'} render={() => <TempleConstruction />} />

        <Route path={'/views/bdona'} render={() => <DonationForm />} />

        <Route
          path={'/views/acert/:sn'}
          render={() => <HonorCertification />}
        />

        <Route path={'/views/sdona'} render={() => <DonationSearch />} />

        <Route
          exact
          path={'/views/asub'}
          render={() => <DonationSubscription />}
        />

        <Route
          exact
          path={'/views/history(.*)?'}
          render={() => <DonationInquiry />}
        />
        <Redirect from={''} to={`/views/home`} />
      </Switch>
    );
  }
}

export default compose(
  withStyles(styles, {
    withTheme: true
  }),
  withRouter
)(MainContainer);
