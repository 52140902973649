import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

class DonationSubscription extends Component<any, any, {}> {
  constructor(props: any) {
    super(props);
    document.title = '观注铁山寺服务号';
  }

  handleOnclick = () => {};

  render() {
    const { classes } = this.props;
    // alert('2');
    // alert(JSON.stringify(donationInfo));
    return (
      <div className={classes.container}>
        <div className={classes.shareFriendCircle}>
          <span>欢迎观注铁山寺服务号</span>
        </div>
        <div className={classes.certificate} />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(DonationSubscription)
);
