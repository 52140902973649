import { createMuiTheme } from '@material-ui/core/styles';
import {
  red,
  yellow,
  blue,
  green,
  teal,
  neutralGrey
} from 'styles/clarity-colors';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

function pxToRem(value) {
  return `${value / 16}rem`;
}
// https://stackoverflow.com/questions/53016167/cannot-access-pxtorem-function-in-material-ui-for-theme

const breakpoints = createBreakpoints({});

const defaultTheme = createMuiTheme({
  breakpoints,
  overrides: {
    MuiTypography: {
      headline: {
        fontSize: pxToRem(20),
        [breakpoints.down('xs')]: {
          fontSize: pxToRem(16)
        }
      },
      title: {
        fontSize: pxToRem(20),
        [breakpoints.down('xs')]: {
          fontSize: pxToRem(16)
        }
      },
      caption: {
        fontSize: pxToRem(14),

        [breakpoints.down('xs')]: {
          fontSize: pxToRem(10)
        }
      },
      body1: {
        fontSize: pxToRem(14),

        [breakpoints.down('xs')]: {
          fontSize: pxToRem(10)
        }
      },
      body2: {
        fontSize: pxToRem(14),
        [breakpoints.down('xs')]: {
          fontSize: pxToRem(10)
        }
      },
      button: {
        fontSize: pxToRem(14),
        [breakpoints.up('xs')]: {
          fontSize: pxToRem(10)
        }
      }
    }
  },
  palette: {
    primary: blue,
    secondary: teal,
    grey: neutralGrey,
    alert: {
      error: {
        main: red[500],
        dark: red[700],
        contrastText: 'white'
      },
      warning: {
        main: yellow[500],
        dark: yellow[700],
        contrastText: 'white'
      },
      success: {
        main: green[500],
        dark: green[600],
        contrastText: 'white'
      },
      poor: {
        main: red[500],
        dark: red[700],
        contrastText: 'white'
      },
      fair: {
        main: yellow[500],
        dark: yellow[700],
        contrastText: 'white'
      },
      good: {
        main: green[500],
        dark: green[600],
        contrastText: 'white'
      }
    },
    chart: {
      default: [
        '#0065AB',
        '#49AFD9',
        '#FFCCB5',
        '#A6D8E7',
        '#25333D',
        '#FF8142',
        '#f28f43',
        '#77a1e5',
        '#c42525',
        '#a6c96a'
      ],
      colorful: [
        '#00BFA9',
        '#FF681C',
        '#314351',
        '#00968B',
        '#FFB38F',
        '#6870C4',
        '#f28f43',
        '#77a1e5',
        '#c42525',
        '#a6c96a'
      ]
    }
  },
  typography: {
    useNextVariants: true,
    pxToRem: size => `${size / 16}rem`,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    title: {
      color: neutralGrey[800]
    },
    headline: {
      color: neutralGrey[800]
    }
  },
  border: {
    color: neutralGrey[500]
  }
});

export default defaultTheme;
