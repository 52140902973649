import { create, SheetsRegistry } from 'jss';
// import preset from 'jss-preset-default';
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import theme from './defaultTheme';

// Configure JSS
// const jss = create(preset());

const jss = create({
  ...jssPreset()
  // We define a custom insertion point that JSS will look for injecting the styles in the DOM.
  // insertionPoint: 'jss-insertion-point',
});

(jss as any).options.createGenerateClassName = createGenerateClassName;

export const sheetsManager = new Map();

export default function createContext() {
  return {
    jss,
    theme,
    // This is needed in order to deduplicate the injection of CSS in the page.
    sheetsManager,
    // This is needed in order to inject the critical CSS.
    sheetsRegistry: new SheetsRegistry()
  };
}
