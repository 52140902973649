import { createStyles } from '@material-ui/core/styles';

const styles = (theme: any) =>
  createStyles({
    container: {
      height: '100vh',
      width: '100vw',
      background: '#976c51',
      padding: '10px'
    },

    content: {
      padding: '20px',
      background: 'white',
      borderRadius: '8px'
    },

    img: {
      paddingTop: '20px',
      paddingBottom: '20px',
      '& img': {
        width: '100%',
        height: '20px'
      }
    },

    table: {
      width: '100%',
      maxHeight: '300px',
      overflowY: 'auto',
      minWidth: 200,
      borderCollapse: 'collapse',

      '& tr': {
        height: '40px'
      },

      '& th, td': {
        padding: '4px 8px',
        border: '1px solid #d1d1d1',
        color: '#888888'
      },

      '& th': {
        width: '30%'
      }
    }
  });
export default styles;
