const color = {
  50: '#FFFCE8',
  100: '#FEF3B5',
  200: '#FFE860',
  300: '#FFDC0B',
  400: '#FDD006',
  500: '#FAC400',
  600: '#EDB200',
  700: '#DFA100',
  800: '#D28F00',
  900: '#C47D00',
  A100: '#FEF3B5',
  A200: '#FFE860',
  A400: '#FDD006',
  A700: '#DFA100',
  contrastDefaultColor: 'dark'
};

export default color;
