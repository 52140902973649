import { createStyles } from '@material-ui/core/styles';

const styles = (theme: any) =>
  createStyles({
    container: {
      height: '100vh',
      width: '100vw',
      background: '#976c51',
      maxWidth: '720px'
    },

    fixedContainer: {
      position: 'fixed',
      zIndex: 9999,
      width: '100vw',
      background: '#976c51',
      maxWidth: '720px'
    },

    typeCardContainer: {
      margin: '15px'
    },

    donatedText: {
      display: 'inline-block',
      marginLeft: '20px',
      height: '30px',
      position: 'relative',
      top: '70%'
    },

    table: {
      position: 'relative',
      top: '40%',
      width: 'auto',
      minWidth: 200,
      marginLeft: '20px',
      marginRight: '20px',
      borderCollapse: 'collapse',

      '& tr': {
        height: '40px'
      },

      '& th, td': {
        border: '1px solid #d6b377'
      }
    },

    shareFriendCircle: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '20px',

      '& img': {
        height: '50px'
      }
    }
  });
export default styles;
