import { createStyles } from '@material-ui/core/styles';

const styles = (theme: any) =>
  createStyles({
    container: {
      position: 'relative',
      top: '1.6rem',
      background: 'white', // '#f0e6d9',
      padding: '15px'
    },

    count: {
      height: '30px',
      color: '#999999',
      fontSize: '1rem',
      textAlign: 'center'
    },

    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow: 'none'
    },

    input: {
      marginLeft: 8,
      flex: 1
    },

    iconButton: {
      padding: 10
    },

    table: {
      width: '100%',
      maxHeight: '300px',
      overflowY: 'auto',
      minWidth: 200,
      borderCollapse: 'collapse',

      '& thead': {
        backgroundColor: '#f6f6f6'
      },

      '& tr': {
        height: '40px'
      },

      '& th, td': {
        border: '1px solid #d1d1d1',
        color: '#888888',
        padding: '4px 5px',
        '&:last-child': {
          padding: 0
        }
      }
    }
  });
export default styles;
