import { createStyles } from '@material-ui/core/styles';

const styles = (theme: any) =>
  createStyles({
    container: {
      height: '100vh',
      width: '100vw',
      background: '#976c51',
      padding: '10px',
      maxHeight: 'max-content',
      overflow: 'auto',
      maxWidth: '720px'
    },

    loadingContainer: {
      height: '100vh',
      width: '100vw',
      background: '#976c51',
      padding: '10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },

    construction: {
      height: '240px',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/board.png"),url("https://sinacloud.net/xbucket1/img/construction.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '8px'
    },

    sutrasPrint: {
      height: '240px',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/board.png"),url("https://sinacloud.net/xbucket1/img/scripture.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '8px'
    },

    triratna: {
      height: '240px',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/board.png"),url("https://sinacloud.net/xbucket1/img/three_treasure.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '8px'
    },

    tssCharity: {
      height: '25%',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/tssCharity.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '8px'
    },

    flower: {
      height: '240px',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/board.png"),url("https://sinacloud.net/xbucket1/img/flower.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '8px'
    },

    fruit: {
      height: '240px',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/board.png"),url("https://sinacloud.net/xbucket1/img/fruit.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '8px'
    },

    lamp: {
      height: '240px',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/board.png"),url("https://sinacloud.net/xbucket1/img/lamp.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '8px'
    },

    buddist_meal: {
      height: '240px',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/board.png"),url("https://sinacloud.net/xbucket1/img/buddist_meal.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '8px'
    },

    buddist_party_meal: {
      height: '240px',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/board.png"),url("https://sinacloud.net/xbucket1/img/buddist_party_meal.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '8px'
    },

    random: {
      height: '240px',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/board.png"),url("https://sinacloud.net/xbucket1/img/random.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '8px'
    },

    slogonContainer: {
      top: '135px',
      paddingLeft: '5px',
      position: 'relative'
    },
    slogon: {
      color: 'white',
      fontSize: '1.2rem',
      textUnderlinePosition: 'below',
      textDecoration: 'underline'
    },

    desc: {
      color: 'white',
      fontSize: '0.75rem'
    },

    typeCardContainer: {
      margin: '15px'
    },

    certificate: {
      display: 'flex',
      justifyContent: 'center',
      height: '68%',
      marginTop: '10px',
      padding: '30px',
      background: `url("https://sinacloud.net/xbucket1/img/certificate.jpg")`,
      backgroundSize: '100% 100%'
    },

    donatedText: {
      display: 'inline-block',
      marginLeft: '20px',
      height: '30px',
      position: 'relative',
      top: '70%'
    },

    table: {
      position: 'relative',
      top: '40%',
      width: '90%',
      minWidth: 200,
      marginLeft: '10px',
      marginRight: '10px',
      borderCollapse: 'collapse',

      '& tr': {
        height: '32px'
      },

      '& th, td': {
        border: '1px solid #d6b377',
        paddingLeft: '10px',
        paddingRight: '10px !important'
      }
    },

    shareFriendCircle: {
      display: 'flex',
      justifyContent: 'center',

      marginTop: '20px',

      '& img': {
        height: '50px'
      }
    },

    progress: {
      margin: '5px'
    }
  });
export default styles;
