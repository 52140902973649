const color = {
  50: '#DEFFF9',
  100: '#A7F4E9',
  200: '#6FEAD9',
  300: '#38DFC8',
  400: '#00D4B8',
  500: '#00BFA9',
  600: '#00AB9A',
  700: '#00968B',
  800: '#007E7A',
  900: '#006668',
  A100: '#A7F4E9',
  A200: '#6FEAD9',
  A400: '#00D4B8',
  A700: '#00968B',
  contrastDefaultColor: 'dark'
};

export default color;
