import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { map, keys } from 'lodash';

import { DONATION_TYPES } from '../../TempleConstruction';
import styles from './styles';

class DonationTypeSelection extends Component<any, any, {}> {
  state = {
    donationType: 'all'
  };

  handleChange = (e: any) => {
    this.setState({
      donationType: e.target.value
    });

    // Send the selected type to parent component
    this.props.onTypeSelectionChange(e.target.value);
  };

  render() {
    const { classes } = this.props;
    const { donationType } = this.state;

    return (
      <div className={classes.container}>
        <FormControl className={classes.formControl}>
          <Select
            value={donationType}
            onChange={this.handleChange}
            name="donationType"
            className={classes.selectEmpty}
          >
            <MenuItem value="all" className={classes.item}>
              全&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;部
            </MenuItem>
            {map(keys(DONATION_TYPES), type => (
              <MenuItem value={type} className={classes.item}>
                {DONATION_TYPES[type as any]}
              </MenuItem>
            ))}
            <MenuItem value="mine" className={classes.item}>
              我的捐赠
            </MenuItem>
          </Select>
        </FormControl>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(DonationTypeSelection)
);
