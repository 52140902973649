import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

import { DONATION_TYPES } from '../../TempleConstruction';
import DonationDescriptionExpandCard from '../DonationDescriptionExpandCard';
import DonationInputsCard from '../DonationInputsCard';

import styles from './styles';

class DonationForm extends Component<any, {}> {
  constructor(props: any) {
    super(props);
    const type = this.props.location.state && this.props.location.state.type;
    document.title = DONATION_TYPES[type];
  }

  render() {
    const { classes, location } = this.props;
    const { state } = location;

    const type = state && state.type;

    return (
      <div className={classes.container}>
        <DonationDescriptionExpandCard type={type} />
        <DonationInputsCard type={type} />
      </div>
    );
  }
}

export default compose(
  withStyles(styles, {
    withTheme: true
  }),
  withRouter
)(DonationForm);
