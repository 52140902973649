import { createStyles } from '@material-ui/core/styles';

const styles = (theme: any) =>
  createStyles({
    formControl: {
      width: '100%',
      margin: theme.spacing.unit * 3,
      'input:required': {
        '&:after': {
          content: ''
        }
      },

      '& label span': {
        color: 'red'
      },

      label: {
        fontSize: 14
      }
    },

    inputContainer: {
      marginTop: '10px',
      padding: '1px',
      border: '1px solid #ced4da',
      borderRadius: '5px'
    },

    group: {
      width: '85%',
      margin: `${theme.spacing.unit}px 0`
    },

    moneyButton: {
      minWidth: '20px',
      padding: 0,
      fontSize: '1.25rem',
      border: '1px solid #976c51',
      color: '#976c51',

      '&:focus': {
        border: '1px solid #976c51',
        backgroundColor: '#976c51'
      },

      '&:hover': {
        border: '1px solid #976c51',
        backgroundColor: '#976c51'
      }
    },

    bootstrapRoot: {
      width: '100%',
      'label + &': {
        marginTop: theme.spacing.unit * 3
      },

      '& input': {
        paddingLeft: '10px',
        paddingRight: '10px'
      }
    },

    bootstrapInput: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      border: '1px solid #ced4da',
      fontSize: '1.25rem',
      width: '30%',
      padding: '5px 5px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
      }
    },

    bootstrapFormLabel: {
      fontSize: '1rem'
    },

    selected: {
      backgroundColor: '#976c51',
      color: 'white'
    },

    errorText: {
      color: 'red'
    },
    bigSize: {
      fontSize: '1.25rem'
    }
  });
export default styles;
