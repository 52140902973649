import { createStyles } from '@material-ui/core/styles';

const styles = (theme: any) =>
  createStyles({
    container: {
      display: 'block',
      height: '100vh',
      width: '100vw',
      background: '#976c51',
      padding: '10px',
      maxWidth: '720px'
    },

    loadingContainer: {
      height: '100vh',
      width: '100vw',
      background: '#976c51',
      padding: '10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },

    certificate: {
      display: 'flex',
      justifyContent: 'center',
      height: '344px',
      width: '344px',
      marginTop: '50px',
      padding: '0px',
      borderWidth: '0px',
      background: `url("https://sinacloud.net/xbucket1/img/tssfuwuhao_qr.jpg")`,
      backgroundSize: '100% 100%'
    },

    shareFriendCircle: {
      display: 'flex',
      justifyContent: 'left',
      marginTop: '20px',

      '& img': {
        height: '50px'
      }
    },
    bigSize: {
      fontSize: '1rem'
    },

    progress: {
      margin: '5px'
    }
  });
export default styles;
