import { createStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';

const styles = (theme: any) =>
  createStyles({
    inputsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      background: 'white',
      marginTop: theme.spacing.unit,
      maxWidth: '720px'
    },

    margin: {
      width: '100%',
      margin: theme.spacing.unit * 2.5,
      marginTop: theme.spacing.unit * 1.5,
      marginBottom: 0,
      'input:required': {
        '&:after': {
          content: '*'
        }
      },

      '& label span': {
        color: 'red'
      }
    },

    bootstrapRoot: {
      'label + &': {
        marginTop: theme.spacing.unit
      }
    },

    bootstrapInput: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      border: '1px solid #ced4da',
      fontSize: '1rem',
      width: '100%',
      padding: '10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
      }
    },
    formLabel: {
      fontSize: '1rem'
    },

    agreementFormLabel: {
      fontSize: '1rem'
    },

    prayMessageContainer: {
      marginTop: 0
    },

    prayMessageLabel: {
      display: 'block',
      fontSize: 18
    },

    textField: {
      width: '100%',
      margin: 0,
      marginTop: theme.spacing.unit
    },

    checkedContainer: {},

    checkbox: {
      marginLeft: '-14px',
      justifyContent: 'flex-start',
      color: green[600],
      '&$checked': {
        color: green[500]
      }
    },

    checked: {},

    adornment: {
      width: '100%',
      paddingLeft: 0
    },

    agreement: {
      color: '#976c51',
      '& input': {
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        marginLeft: -theme.spacing.unit,
        width: 0 // Not permit input anything for agreement checkbox input
      }
    },

    submitButtonContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      borderColor: '#b6b6b6b'
    },

    submitButton: {
      width: '50%',
      margin: theme.spacing.unit,
      background: '#ac8164',
      color: 'white',

      '&:hover': {
        background: '#ac8164'
      },

      '& span': {
        width: '150px'
      }
    },

    submitButtonDisabled: {
      backgroundColor: '#c89a79 !important',

      '& span': {
        color: 'white'
      }
    },

    templeName: {
      textAlign: 'center',
      marginTop: '10px',
      '& img': {
        display: 'inline-block',
        height: '24px'
      }
    }
  });
export default styles;
