const color = {
  50: '#E1F1F6',
  100: '#C5E5EF',
  200: '#A6D8E7',
  300: '#89CBDF',
  400: '#49AFD9',
  500: '#0095D3',
  600: '#007CBB',
  700: '#0065AB',
  800: '#004D8A',
  900: '#003D79',
  A100: '#C5E5EF',
  A200: '#A6D8E7',
  A400: '#49AFD9',
  A700: '#0065AB',
  contrastDefaultColor: 'light'
};

export default color;
