import { createStyles } from '@material-ui/core/styles';

const styles = (theme: any) =>
  createStyles({
    container: {
      backgroundColor: '#976c51',
      height: '100%',
      width: '100vw',
      padding: theme.spacing.unit,
      fontSize: '1rem',
      maxWidth: '720px'
    },

    cardsContent: {
      height: '100%'
    },

    cardContent: {
      height: '24%',
      marginBottom: '10px',
      backgroundSize: '100% 100%'
    },

    construction: {
      height: '280px',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/board.png"),url("https://sinacloud.net/xbucket1/img/construction.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '8px'
    },

    sutrasPrint: {
      height: '280px',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/board.png"),url("https://sinacloud.net/xbucket1/img/scripture.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '8px'
    },

    triratna: {
      height: '280px',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/board.png"),url("https://sinacloud.net/xbucket1/img/scripture.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '8px'
    },

    tssCharity: {
      height: '25%',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/tssCharity.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '8px'
    },

    flower: {
      height: '280px',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/board.png"),url("https://sinacloud.net/xbucket1/img/flower.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '8px'
    },

    fruit: {
      height: '280px',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/board.png"),url("https://sinacloud.net/xbucket1/img/fruit.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '8px'
    },

    lamp: {
      height: '280px',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/board.png"),url("https://sinacloud.net/xbucket1/img/lamp.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '8px'
    },

    buddist_meal: {
      height: '280px',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/board.png"),url("https://sinacloud.net/xbucket1/img/buddist_meal.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '8px'
    },

    buddist_party_meal: {
      height: '280px',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/board.png"),url("https://sinacloud.net/xbucket1/img/buddist_party_meal.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '8px'
    },

    random: {
      height: '280px',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/board.png"),url("https://sinacloud.net/xbucket1/img/random.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '8px'
    },
    slogonContainer: {
      top: '135px',
      paddingLeft: '5px',
      position: 'relative'
    },
    slogon: {
      color: 'white',
      fontSize: '1.2rem',
      textUnderlinePosition: 'below',
      textDecoration: 'underline'
    },

    desc: {
      color: 'white',
      fontSize: '0.75rem'
    },

    button: {
      position: 'relative',
      top: '70%',
      left: '10%',
      borderRadius: '20px 20px',
      backgroundColor: 'white',
      color: '#866149',

      '&:hover': {
        backgroundColor: 'white'
      }
    }
  });
export default styles;
