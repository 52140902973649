import { createStyles } from '@material-ui/core/styles';

const styles = (theme: any) => {
  return createStyles({
    container: {
      height: '100vh',
      width: '100vw',
      maxWidth: '720px',
      fontSize: '1rem'
    },

    cardsContent: {
      height: '100%'
    },

    cardContent: {
      height: '260px',
      marginBottom: '10px',
      backgroundSize: '100% auto'
    },

    construction: {
      height: '260px',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/board.png"),url("https://sinacloud.net/xbucket1/img/construction.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '12px'
    },

    sutrasPrint: {
      height: '260px',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/board.png"),url("https://sinacloud.net/xbucket1/img/scripture.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '12px'
    },

    triratna: {
      height: '260px',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/board.png"),url("https://sinacloud.net/xbucket1/img/three_treasure.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '12px'
    },

    tssCharity: {
      height: '25%',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/tssCharity.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '12px'
    },

    flower: {
      height: '260px',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/board.png"),url("https://sinacloud.net/xbucket1/img/flower.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '12px'
    },

    fruit: {
      height: '260px',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/board.png"),url("https://sinacloud.net/xbucket1/img/fruit.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '12px'
    },

    lamp: {
      height: '260px',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/board.png"),url("https://sinacloud.net/xbucket1/img/lamp.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '12px'
    },

    buddist_meal: {
      height: '260px',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/board.png"),url("https://sinacloud.net/xbucket1/img/buddist_meal.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '12px'
    },

    buddist_party_meal: {
      height: '260px',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/board.png"),url("https://sinacloud.net/xbucket1/img/buddist_party_meal.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '12px'
    },

    random: {
      height: '260px',
      marginBottom: '2px',
      background: `url("https://sinacloud.net/xbucket1/img/board.png"),url("https://sinacloud.net/xbucket1/img/random.jpg")`,
      backgroundSize: '100% auto',
      borderRadius: '12px'
    },

    button: {
      // left: '25%',
      width: '108px',
      height: '32px',
      borderRadius: '20px 20px',
      backgroundColor: '#fff3df',
      color: '#744c35',
      fontSize: '1rem',

      '&:hover': {
        backgroundColor: '#fff3df'
      },

      '&:focus': {
        backgroundColor: '#fff3df'
      }
    },

    buttonContainer: {
      position: 'relative',
      top: '160px',
      margin: '0px',
      padding: '0px',
      display: 'flex',
      justifyContent: 'center'
    },

    donationButton: {
      width: '100%',
      height: 'auto'
    },

    slogonContainer: {
      top: '135px',
      paddingLeft: '5px',
      position: 'relative'
    },

    slogon: {
      color: 'white',
      fontSize: '1.2rem'
    },

    desc: {
      color: 'white',
      fontSize: '0.75rem'
    }
  });
};
export default styles;
