import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { keys, map } from 'lodash';

import styles from './styles';

enum TABLE_HEADER {
  donator = '捐款人',
  donationType = '捐款项目',
  donationAccount = '捐款金额',
  donationDate = '捐款日期'
}

class DonationTable extends Component<any, any, {}> {
  state = {
    selectedIndex: '0'
  };

  handleOnClick = (index: string) => {
    this.setState({
      selectedIndex: index
    });

    this.props.history.push('/views/history');
  };

  render() {
    const { classes, donations } = this.props;
    const { selectedIndex } = this.state;

    // <span>共{size(donations)}数据</span>

    return (
      <div className={classes.container}>
        <div className={classes.count}>
          <span>(显示最近200条记录)</span>
        </div>
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {map(keys(TABLE_HEADER), (headerName: any) => (
                  <TableCell align="center">
                    {TABLE_HEADER[headerName]}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {map(donations, (item, index) => (
                <TableRow
                  key={`${item.donator}_${index}`}
                  selected={selectedIndex === index}
                  hover={selectedIndex === index}
                  // onClick={this.handleOnClick.bind(this, index)}
                >
                  <TableCell component="th" scope="row" align="center">
                    {item.donator}
                  </TableCell>
                  <TableCell align="center">
                    <a href={`acert/${item.sn}`}>{item.type}</a>
                  </TableCell>
                  <TableCell align="center">{item.amount}</TableCell>
                  <TableCell align="center">{item.date}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(DonationTable)
);
