import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { DONATION_TYPES } from '../TempleConstruction';
import { map, keys } from 'lodash';

import styles from './styles';

export interface ClusterListParams {
  country: string;
  state: string;
  area: string;
}

enum DONATION_HEADER_NAME {
  donator = '捐赠人',
  type = '捐赠项目',
  amount = '捐赠金额',
  date = '捐赠日期',
  sn = '编号',
  preyMessage = '祈愿文'
}

const donationInformation =
  'https://sinacloud.net/xbucket1/img/donationInformation.png';

class DonationInquiry extends Component<any, any, {}> {
  constructor(props: any) {
    super(props);
    document.title = '捐赠查询';
  }

  static defaultProps = {
    type: 'constructor'
  };

  handleOnclick = () => {};

  render() {
    const { classes, location } = this.props;
    let type = location.state && location.state.type;
    type = type || keys(DONATION_TYPES)[0];

    // Mock data
    const donation = {
      donator: '马小喜',
      type: DONATION_TYPES[type],
      amount: '100 元',
      date: '2019/06/01',
      sn: '20190601106',
      preyMessage: '愿此殊胜功德，回向法界有情，尽除一切罪障，共成无上菩提'
    };

    return (
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.img}>
            <img alt="" src={donationInformation} />
          </div>
          <div className={classes.certificate}>
            <Table className={classes.table}>
              <TableBody>
                {map(donation, (value, key: any) => (
                  <TableRow className={classes.row} key={key}>
                    <TableCell component="th" scope="row">
                      {DONATION_HEADER_NAME[key]}
                    </TableCell>
                    <TableCell align="left">{value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(DonationInquiry)
);
