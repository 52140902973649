import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { map, keys } from 'lodash';
import styles from './styles';

export interface ClusterListParams {
  country: string;
  state: string;
  area: string;
}

export enum DONATION_TYPES {
  'triratna' = '供养三宝',
  'construction' = '寺院建设',
  'sutrasPrint' = '经书助印',
  'flower' = '供花',
  'fruit' = '供果',
  'lamp' = '供灯',
  'buddist_meal' = '供僧',
  'buddist_party_meal' = '供斋',
  'random' = '随缘捐赠'
}

export enum DONATION_TYPES_R {
  '寺院建设' = 'construction',
  '供养三宝' = 'triratna',
  '经书助印' = 'sutrasPrint',
  '铁山慈善' = 'tssCharity',
  '供花' = 'flower',
  '供果' = 'fruit',
  '供灯' = 'lamp',
  '供僧' = 'buddist_meal',
  '供斋' = 'buddist_party_meal',
  '随缘捐赠' = 'random'
}

export enum DONATION_TYPES_NO {
  'construction' = '1',
  'triratna' = '2',
  'sutrasPrint' = '3',
  'tssCharity' = '4',
  'flower' = 5,
  'fruit' = 6,
  'lamp' = 7,
  'buddist_meal' = 8,
  'buddist_party_meal' = 9,
  'random' = 16
}

const DONATION_TYPES_SHORT_DESC = {
  construction: [
    '寺院建设',
    '所言所行，人天欢喜',
    '往生善道，相貌端庄，天资福禄兼有'
  ],
  triratna: ['供养三宝', '身相端严，气力增盛', '寿命延长，快乐安隐，成就辩才'],
  sutrasPrint: [
    '经书助印',
    '心得安慰，日无险事，夜无恶梦',
    '颜色光泽，气力充盛，所作吉利'
  ],
  //'tssCharity' = '铁山慈善',
  flower: ['供花', '福香戒香，遍诸方所', '超胜世间，为众归仰'],
  fruit: ['供果', '种善因，得善果', '种恶因，得苦果'],
  lamp: ['供灯', '一灯能破千年暗', '一智能灭万年愚'],
  buddist_meal: [
    '供僧',
    '往昔七世父母、六亲眷属得远离三恶道',
    '现世父母及其本人，长寿无病无恼'
  ],
  buddist_party_meal: [
    '供斋',
    '上供十方诸佛，中奉诸圣贤',
    '下及三途六道，一切有情'
  ],
  random: ['随缘捐赠', '诸恶莫作，众善奉行', '自净其意，是诸佛教']
};

export function shortDesc() {
  return DONATION_TYPES_SHORT_DESC;
}

const donateIcon = 'https://sinacloud.net/xbucket1/img/donateButton.png'; //require('../../images/donateButton.png');
const donationSuccessIcon =
  'https://sinacloud.net/xbucket1/img/donationSuccess.png';

export function SingleCard(props: {
  classes: any;
  backgroundType: any;
  handleOnClick: Function;
  donated?: boolean;
}) {
  const { classes, backgroundType, handleOnClick, donated } = props;
  // const { background } = item;
  const cardClass = classes && classes[backgroundType];
  return (
    <div className={cardClass}>
      <div className={classes.slogonContainer}>
        <span className={classes.slogon}>
          {(DONATION_TYPES_SHORT_DESC as any)[backgroundType][0]}
        </span>
        <br />
        <span className={classes.desc}>
          {(DONATION_TYPES_SHORT_DESC as any)[backgroundType][1]}
        </span>
        <br />
        <span className={classes.desc}>
          {(DONATION_TYPES_SHORT_DESC as any)[backgroundType][2]}
        </span>
      </div>
      {donated ? (
        <img
          alt="捐赠成功"
          src={donationSuccessIcon}
          className={classes.donatedText}
        />
      ) : (
        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            className={classes.button}
            onClick={() => handleOnClick(backgroundType)}
          >
            <img
              alt=""
              src={donated ? donationSuccessIcon : donateIcon}
              className={donated ? classes.donatedText : classes.donationButton}
            />
          </Button>
        </div>
      )}
    </div>
  );
}

class TempleConstruction extends Component<any, any, {}> {
  constructor(props: any) {
    super(props);
    document.title = '铁山寺捐赠服务';
  }

  handleButtonClick = (background: string) => {
    this.props.history.push({
      pathname: `/views/bdona`,
      state: { type: background }
    });

    sessionStorage.setItem('donationType', background);
  };

  render() {
    const { classes } = this.props; // donationItems

    return (
      <div className={classes.container}>
        <Grid container spacing={24} className={classes.cardsContent}>
          <Grid item xs={12}>
            {map(keys(DONATION_TYPES), type => (
              <SingleCard
                key={type}
                classes={classes}
                backgroundType={type}
                handleOnClick={this.handleButtonClick}
              />
            ))}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default compose(
  withStyles(styles, {
    withTheme: true
  }),
  withRouter
)(TempleConstruction);
